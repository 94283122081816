<template>
    <v-container fluid>
        <h3 style="font-size:25px;color:#172C54;white-space: pre-line;word-break: break-word;" class="mb-8"
            id="saved-notice">Funeral Notice
            Generator</h3>
        <v-card v-if="user.get_funeral_notice != null" elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;"
            class="mb-3 mt-8 pt-2">
            <v-card-subtitle style="font-size:18px;white-space: pre-line;word-break: break-word;" class="mb-5">
                Edit and view your saved funeral notice:
                <v-btn text color="#172C54" class="ml-2" @click="open_edit_funeral_notice()">Edit</v-btn>
                <v-btn dark color="#172C54" class="ml-2" @click="view_funeral_notice()">View</v-btn>
                <br>
                <br>
                Or generate a new funeral notice below.
            </v-card-subtitle>
        </v-card>
        <v-card elevation="0" style="border:solid 2px #F3F3F3;border-radius:10px;" class="mb-3 mt-8 pb-10">
            <v-card-subtitle style="font-size:18px;white-space: pre-line;word-break: break-word;" class="mb-5">
                <v-tabs id="tab_header" show-arrows :value="tab" icons-and-text style="display:flex;justify-content:center;"
                    class="mb-5">
                    <v-tabs-slider style="display:none;"></v-tabs-slider>
                    <v-tab v-for="(nav, index) in notice_tabs" :key="index" :href="`#notice_tab-${index}`"
                        style="text-transform: none !important;border-radius:16px;"
                        :class="{ active: `notice_tab-${index}` == tab }" @click="change_tab(index)">
                        <p style="font-size:15px;white-space:pre-line;word-break:break-word;padding-top:20px;width:120px">
                            {{ nav.label }}
                        </p>
                        <v-avatar size="55" color="#172C54" style="position: relative;">
                            <strong style="font-size: 22px;color:white;">{{ index + 1 }}</strong>
                        </v-avatar>
                    </v-tab>
                </v-tabs>
                <v-tabs-items :value="tab" style="width: 100%;">
                    <v-tab-item value="notice_tab-0">
                        <v-row :class="isMobile() ? '' : 'px-5'">
                            <v-col cols="12">
                                <v-btn color="#172C54" dark style="float:right;" @click="change_tab(1)">Next</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <p style="font-size:14px;">Welcome to our Funeral Notice Generator. The information that you
                                    share with us will allow us to start
                                    writing the funeral notice / obituary. Feel free to skip any sections that you feel are
                                    not relevant to
                                    you.</p>
                                <p style="font-size:14px;">You will see that you can preview a draft funeral notice. Please
                                    note that we will
                                    put the finishing touches on this notice before it is used.</p>
                                <h4 v-if="user.representative != null">Details of the Deceased</h4>
                                <h4 v-else>Your Information</h4>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-text-field label="First Name *" outlined dense v-model="user_info.firstname">
                                </v-text-field>
                                <v-select :items="['his', 'her']" outlined dense label="Pronoun"
                                    v-model="user_info.pronoun">
                                </v-select>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-text-field label="Last Name *" outlined dense v-model="user_info.lastname">
                                </v-text-field>
                                <v-text-field label="Predeceased by" outlined dense v-model="family_info.predeceased_by">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <h4 v-if="user.representative != null">Address of the Deceased</h4>
                                <h4 v-else>Your Address</h4>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-text-field label="Town" outlined dense v-model="user_info.town">
                                </v-text-field>
                                <v-text-field label="Eircode" outlined dense v-model="user_info.eircode">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-text-field label="County" outlined dense v-model="user_info.county">
                                </v-text-field>
                                <v-text-field label="Address" outlined dense v-model="user_info.address">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="#172C54" dark style="float:right;" @click="change_tab(1)">Next</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item value="notice_tab-1">
                        <v-row :class="isMobile() ? '' : 'px-5'">
                            <v-col cols="12">
                                <v-btn color="#172C54" dark @click="change_tab(0)">Back</v-btn>
                                <v-btn color="#172C54" dark style="float:right;" @click="change_tab(2)">Next</v-btn>
                            </v-col>
                            <v-col cols="12" v-if="user.representative != null">
                                <h4>Details of the Deceased</h4>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6" v-if="user.representative != null">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="user_info.date_of_death" label="Date of Death"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="user_info.date_of_death" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <v-text-field label="Circumstances" outlined dense
                                    v-model="user_info.circumstance_of_death">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6" v-if="user.representative != null">
                                <v-text-field label="Place of Death" outlined dense v-model="user_info.place_of_death">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <h4>Family Members</h4>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-select label="Significant Other" :items="['husband', 'wife', 'partner']" outlined
                                    dense v-model="family_info.significant_other_type">
                                </v-select>
                                <v-text-field label="Father" outlined dense v-model="family_info.father">
                                </v-text-field>
                                <v-text-field label="Brothers" outlined dense v-model="family_info.brothers">
                                </v-text-field>
                                <v-text-field label="Sons" outlined dense v-model="family_info.sons">
                                </v-text-field>
                                <v-text-field label="Sons-in-law" outlined dense v-model="family_info.sons_in_law">
                                </v-text-field>
                                <v-text-field label="Grandchildren" outlined dense v-model="family_info.grandchildren">
                                </v-text-field>
                                <v-text-field label="Other Relevant People to Mention" outlined dense
                                    v-model="family_info.other_people">
                                </v-text-field>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-text-field label="Name of Significant Other" outlined dense
                                    v-model="family_info.significant_other">
                                </v-text-field>
                                <v-text-field label="Mother" outlined dense v-model="family_info.mother">
                                </v-text-field>
                                <v-text-field label="Sisters" outlined dense v-model="family_info.sisters">
                                </v-text-field>
                                <v-text-field label="Daughters" outlined dense v-model="family_info.daughters">
                                </v-text-field>
                                <v-text-field label="Daughters-in-law" outlined dense
                                    v-model="family_info.daughters_in_law">
                                </v-text-field>
                                <v-text-field label="Greatgrandchildren" outlined dense
                                    v-model="family_info.greatgrandchildren">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">Photograph for Funeral Notice</v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-file-input label="Choose photograph" outlined dense v-model="funeral_notice_image"
                                    @change="edit_funeral_notice_image()">
                                </v-file-input>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <v-img v-if="user.funeral_notice_image != null" contain max-height="300px"
                                    :src="`${api_url}${user.funeral_notice_image}`" />
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="#172C54" dark @click="change_tab(0)">Back</v-btn>
                                <v-btn color="#172C54" dark style="float:right;" @click="change_tab(2)">Next</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item value="notice_tab-2">
                        <v-row class="px-5">
                            <v-col cols="12">
                                <v-btn color="#172C54" dark @click="change_tab(1)">Back</v-btn>
                                <v-btn color="#172C54" dark style="float:right;" @click="save_funeral_notice">Save</v-btn>
                                <br>
                                <small style="font-size:12px;width:100%;text-align:right;display:block;">You may edit this
                                    further after saving</small>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 3">
                                <v-img v-if="user.funeral_notice_image != null" contain max-height="300px"
                                    :src="`${api_url}${user.funeral_notice_image}`" />
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 9"
                                style="font-size:14px;white-space:pre-line;word-break:break-word;">
                                {{ funeral_notice }}
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="#172C54" dark @click="change_tab(1)">Back</v-btn>
                                <v-btn color="#172C54" dark style="float:right;" @click="save_funeral_notice">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-subtitle>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="dialog_edit_notice" persistent max-width="600">
                <v-card v-if="notice_to_edit != null">
                    <v-card-title class="text-h5">
                        Edit funeral notice
                    </v-card-title>
                    <v-card-text class="mt-5">
                        <v-textarea rows="10" filled v-model="notice_to_edit" dense>
                        </v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog_edit_notice = false">
                            Cancel
                        </v-btn>
                        <v-btn color="#1976D2" dark class="pl-10 pr-10" @click="edit_funeral_notice()">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
const Mustache = require('mustache');
export default {
    components: {
    },
    props: [
    ],
    mounted() {
        this.set_user_info()
    },
    created() {
        this.$store.dispatch('auth/fetch_user')
        this.api_url = process.env.VUE_APP_API_URL
        this.app_url = process.env.VUE_APP_BUILD
    },
    computed: {
        ...mapGetters({
            user: "auth/get_user"
        })
    },
    data: () => ({
        app_url: null,
        tab: 'notice_tab-0',
        funeral_notice: null,
        notice_tabs: [
            { label: "Contact Details" },
            { label: "Funeral Notice Information" },
            { label: "Funeral Notice Generator" },
        ],
        api_url: null,
        funeral_notice_image: null,
        user_info: {
            id: null,
            firstname: null,
            lastname: null,
            pronoun: null,
            address: null,
            country: null,
            county: null,
            town: null,
            eircode: null,
            date_of_death: null,
            circumstance_of_death: null,
            place_of_death: null,
        },
        family_info: {
            significant_other_type: null,
            significant_other: null,
            father: null,
            mother: null,
            brothers: null,
            sisters: null,
            sons: null,
            daughters: null,
            sons_in_law: null,
            daughters_in_law: null,
            grandchildren: null,
            greatgrandchildren: null,
            other_people: null,
            predeceased_by: null,
        },
        funeral_notice_template: null,
        notice_to_edit: null,
        dialog_edit_notice: false,
        menu: false,
    }),
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        view_funeral_notice() {
            window.open('/funeral-notice/' + this.user.get_funeral_notice.link)
        },
        open_edit_funeral_notice() {
            this.notice_to_edit = this.user.get_funeral_notice.notice
            this.dialog_edit_notice = true
        },
        async edit_funeral_notice() {
            let payload = {
                user_id: this.user.id,
                template_id: this.user.get_funeral_notice.template_id,
                notice: this.notice_to_edit,
            }
            await this.$axios.post('/auth/client/funeral_notice/save_funeral_notice', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.dialog_edit_notice = false
                        this.$store.dispatch('auth/fetch_user')
                        this.showSnackBar("Saved successfully")
                    }
                })
        },
        async save_funeral_notice() {
            let payload = {
                user_id: this.user.id,
                template_id: this.funeral_notice_template.id,
                notice: this.funeral_notice,
            }
            await this.$axios.post('/auth/client/funeral_notice/save_funeral_notice', payload)
                .then(() => {
                    this.showSnackBar("Saved successfully")
                    this.$store.dispatch('auth/fetch_user')
                    setTimeout(() => {
                        this.$vuetify.goTo('#saved-notice', { offset: 50 });
                    }, 300)
                })
        },
        async change_tab(index) {
            this.$vuetify.goTo('#tab_header', { offset: 50 });

            if (index < 2) {
                this.tab = 'notice_tab-' + index
            }
            else {
                this.showSnackBar("Please wait...")
            }

            let payload = {
                user_id: this.user.id,
                firstname: this.user_info.firstname,
                lastname: this.user_info.lastname,
                pronoun: this.user_info.pronoun,
                country: this.user_info.country,
                county: this.user_info.county,
                town: this.user_info.town,
                address: this.user_info.address,
                eircode: this.user_info.eircode,
                family: this.family_info,
                date_of_death: this.user_info.date_of_death,
                place_of_death: this.user_info.place_of_death,
                circumstance_of_death: this.user_info.circumstance_of_death,
            }
            await this.$axios.patch('/auth/client/edit_from_funeral_notice', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/set_user_info', data.data)
                        if (index == 2) {
                            this.generate_funeral_notice()
                        }
                    }
                })
            this.set_user_info()
        },
        async edit_funeral_notice_image() {
            const formData = new FormData()
            formData.append('file', this.funeral_notice_image)
            formData.append('user_id', this.user.id)
            await this.$axios.post('/auth/client/edit_funeral_notice_image', formData)
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('auth/set_user_info', data.data)
                    }
                })
        },
        set_user_info() {
            this.user_info = {
                id: this.user.id,
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                pronoun: this.user.pronoun,
                address: this.user.address,
                country: this.user.country,
                county: this.user.county,
                town: this.user.town,
                eircode: this.user.eircode,
                date_of_death: this.user.date_of_death,
                circumstance_of_death: this.user.circumstance_of_death,
                place_of_death: this.user.place_of_death,
            }
            if (this.user.family != null) {
                this.family_info = this.user.family
            }
        },
        async generate_funeral_notice() {
            let payload = { fd_id: this.user.get_user_add_info.funeral_director_id }
            await this.$axios.get('/auth/client/funeral_notice/get_active_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.funeral_notice_template = data.data
                        this.funeral_notice = Mustache.render(this.funeral_notice_template.template, this.user)
                        this.tab = 'notice_tab-2'
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.active {
    background-color: #DFE9FF;
}
</style>