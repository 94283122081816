<template>
  <v-app>
    <v-main>
      <v-snackbar v-model="mSnackbar.show" bottom="bottom" multi-line="multi-line" :timeout="4000" :vertical="true">
        {{ mSnackbar.message }}<br />
        <v-btn v-if="mSnackbar.button" @click="sendotp" color="primary">Click here to send out new OTP</v-btn>
      </v-snackbar>
      <component :is="layout">
        <router-view />
      </component>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout
    },
    ...mapGetters({
      mSnackbar: 'auth/mSnackbar',
      get_token: 'auth/get_token'
    })
  },
  async mounted() {
    if ((this.get_token !== null || this.get_token !== '') && this.layout !== 'survey-layout' && this.layout !== 'public-layout' && this.layout !== 'will-layout') {
      await this.$axios.get('/auth/is_auth')
        .then(({ data }) => {
          if (!data.response) {
            this.$store.commit('auth/set_empty_data')
            this.$router.push({ name: '/' })
          }
        })
    }
  },
  components: {
  },

  data: () => ({
    //
  }),
};
</script>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>